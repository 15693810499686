<!-- 工时管理 > 工时填报 > 工时填报(弹出框) -->
<template>
  <div>
    <el-dialog
      width="70%"
      :before-close="handleClose"
      :visible.sync="isEditDlg"
      :close-on-click-modal="false"
      class="fix-dialog-position"
      v-loading="loading"
    >
      <div style="position: relative">
        <div slot="title" class="dialog-title">
          <span class="title-text">工时填报</span>
        </div>
        <div class="date_div">
          <h3>当前日期：{{ getDate }}</h3>
        </div>
        <div class="form_box">
          <div class="tips_div">
            <div class="tips_div_left">
              <div>项目名称：</div>
              <el-autocomplete
                style="width: 500px; margin-right: 10px"
                class="ipt_width project_width"
                v-model="searchProjectName"
                :fetch-suggestions="projectQuerySearch"
                placeholder="请输入或选择项目名"
                @select="onProkectNameSelect"
                :popper-append-to-body="false"
              >
              </el-autocomplete>
              <!-- <el-button size="small" type="primary" @click="searchProjectHour">搜索</el-button> -->
            </div>
            <!-- <div class="tips_div_right">
              <div class="tips_div_height" v-if="onlyProjectHour"></div>
              <div class="tips_div_height" v-else>非项目工时请点击“新增”填写相应工时及工作内容</div>
            </div> -->
          </div>
          <!-- 不是综合部的成员 -->
          <!-- <el-scrollbar class="scrollbar_div" ref="leftScrollbar" v-if="!isZHB || isShowAll"> -->
          <el-scrollbar class="scrollbar_div" ref="leftScrollbar">
            <div class="project_div">
              <ul class="form_header">
                <li>项目名称</li>
                <li>项目时间</li>
                <li>有无重要活动</li>
                <!-- <li>类型</li> -->
                <li>工时(小时)</li>
                <li>工作内容</li>
              </ul>
              <ul class="form_body" v-for="(item, index) in projectList" :key="index">
                <li>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="item.projectName"
                    placement="top-start"
                  >
                  </el-tooltip>
                  {{ item.projectName }}
                </li>
                <li>
                  <div>
                    <p>{{ item.begin_time }}</p>
                    <p>/</p>
                    <p>{{ item.end_time }}</p>
                  </div>
                </li>
                <!-- 有无重要活动复选框区域 -->
                <li>
                  <div class="out-box">
                    <div>
                      <el-checkbox
                        v-model="item.isGoOut"
                        :disabled="
                          item.checkStatus == 2 ||
                          item.checkStatus == 3 ||
                          (panduan() == 1 && isShowSubmit == 4)
                        "
                        @change="onOutSwitchChange(item, index, $event, 'project')"
                        >有</el-checkbox
                      >
                    </div>
                    <el-link
                      v-if="item.isGoOut"
                      class="out-a"
                      type="warning"
                      @click="openOutReportDialog(item, index, 'project')"
                      >查看报告</el-link
                    >
                  </div>
                </li>
                <li>
                  <el-input
                    v-model.number="item.hour"
                    @input="v => (item.hour = v.replace(/[^\d.]/g, ''))"
                    :disabled="item.checkStatus == 2 || item.checkStatus == 3 || panduan() == 1"
                  >
                  </el-input>
                  <!-- onkeyup="value=value.replace(/[^\d]/g,'')"  -->
                </li>
                <li>
                  <el-input
                    type="textarea"
                    v-model="item.workContent"
                    :disabled="item.checkStatus == 2 || item.checkStatus == 3 || panduan() == 1"
                    :autosize="{ minRows: 4, maxRows: 5 }"
                  >
                  </el-input>
                </li>
              </ul>
            </div>

            <div class="work_div work_div_margintop" v-show="workList.length > 0">
              <ul class="form_header">
                <li>组织名称</li>
                <li>有无重要活动</li>
                <li>工时(小时)</li>
                <li>工作内容</li>
              </ul>
              <ul
                class="form_body"
                v-for="(item, index) in workList"
                :key="index"
                v-if="item.checkStatus != 4"
              >
                <li>
                  <!-- <el-select
                    v-model="item.companyType"
                    :disabled="item.checkStatus == 2 || item.checkStatus == 3 || panduan() == 1"
                    placeholder="请选择组织名称"
                    @visible-change="onChangeWork"
                  >
                    <el-option
                      v-for="(item, i) in listDept"
                      v-show="!selectIdsArr.includes(item)"
                      :key="i"
                      :label="i"
                      :value="item"
                    >
                    </el-option>
                  </el-select> -->

                  <DeptCascader
                    :placeholder="'请选择组织名称'"
                    :deptList="deptList"
                    :deptIdArr="item.deptIdArr"
                    style="width: 100%"
                    :clearable="false"
                    :disabled="item.checkStatus == 2 || item.checkStatus == 3 || panduan() == 1"
                    @changeValue="item.deptId = $event"
                    @changeValueArr="item.deptIdArr = $event"
                  />
                </li>
                <!-- 有无重要活动复选框区域 -->
                <li>
                  <div class="out-box">
                    <div>
                      <el-checkbox
                        v-model="item.isGoOut"
                        :disabled="
                          item.checkStatus == 2 ||
                          item.checkStatus == 3 ||
                          (panduan() == 1 && isShowSubmit == 4)
                        "
                        @change="onOutSwitchChange(item, index, $event, 'organization')"
                        >有</el-checkbox
                      >
                    </div>
                    <el-link
                      v-if="item.isGoOut"
                      class="out-a"
                      type="warning"
                      @click="openOutReportDialog(item, index, 'organization')"
                      >查看报告</el-link
                    >
                  </div>
                </li>
                <li>
                  <el-input
                    v-model="item.hour"
                    @input="v => (item.hour = v.replace(/[^\d.]/g, ''))"
                    :disabled="item.checkStatus == 2 || item.checkStatus == 3 || panduan() == 1"
                  >
                  </el-input>
                </li>
                <li>
                  <el-input
                    v-model="item.workContent"
                    type="textarea"
                    :disabled="item.checkStatus == 2 || item.checkStatus == 3 || panduan() == 1"
                    :autosize="{ minRows: 4, maxRows: 5 }"
                  >
                  </el-input>
                </li>
                <li style="transform: translateY(10px)">
                  <el-button
                    type="danger"
                    size="mini"
                    icon="el-icon-delete"
                    circle
                    :disabled="
                      item.checkStatus == 2 ||
                      item.checkStatus == 3 ||
                      isShowSubmit != 4 ||
                      panduan() == 1
                    "
                    @click="handleDeleteItem(index)"
                  >
                  </el-button>
                </li>
              </ul>
            </div>
          </el-scrollbar>

          <!-- <el-scrollbar class="scrollbar_div" ref="leftScrollbar" v-else>
            <div class="project_div" v-if="onlyProjectHour && !onlyOrgHour">
              <ul class="form_header">
                <li>项目名称</li>
                <li>项目时间</li>
                <li>有无重要活动</li>
                <li>工时(小时)</li>
                <li>工作内容</li>
              </ul>
              <ul class="form_body" v-for="(item, index) in projectList" :key="index">
                <li>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="item.projectName"
                    placement="top-start"
                  >
                  </el-tooltip>
                  {{ item.projectName }}
                </li>
                <li>
                  <div>
                    <p>{{ item.begin_time }}</p>
                    <p>/</p>
                    <p>{{ item.end_time }}</p>
                  </div>
                </li>
                <li>
                  <div class="out-box">
                    <div>
                      <el-checkbox
                        v-model="item.isGoOut"
                        :disabled="
                          item.checkStatus == 2 ||
                          item.checkStatus == 3 ||
                          (panduan() == 1 && isShowSubmit == 4)
                        "
                        @change="onOutSwitchChange(item, index, $event, 'project')"
                        >有</el-checkbox
                      >
                    </div>
                    <el-link
                      v-if="item.isGoOut"
                      class="out-a"
                      type="warning"
                      @click="openOutReportDialog(item, index, 'project')"
                      >查看报告</el-link
                    >
                  </div>
                </li>
                <li>
                  <el-input
                    v-model.number="item.hour"
                    @input="v => (item.hour = v.replace(/[^\d.]/g, ''))"
                    :disabled="item.checkStatus == 2 || item.checkStatus == 3 || panduan() == 1"
                  >
                  </el-input>
                </li>
                <li>
                  <el-input
                    type="textarea"
                    v-model="item.workContent"
                    :disabled="item.checkStatus == 2 || item.checkStatus == 3 || panduan() == 1"
                    :autosize="{ minRows: 4, maxRows: 5 }"
                  >
                  </el-input>
                </li>
              </ul>
            </div>

            <div
              :class="[
                onlyOrgHour && !onlyProjectHour ? 'work_div' : 'work_div work_div_margintop',
              ]"
              v-if="onlyOrgHour && !onlyProjectHour"
              v-show="workList.length > 0"
            >
              <ul class="form_header">
                <li>组织名称</li>
                <li>有无重要活动</li>
                <li>工时(小时)</li>
                <li>工作内容</li>
              </ul>
              <ul
                class="form_body"
                v-for="(item, index) in workList"
                :key="index"
                v-if="item.checkStatus != 4"
              >
                <li>
             

                  <DeptCascader
                    :placeholder="'请选择组织名称'"
                    :deptList="deptList"
                    :deptIdArr="item.deptIdArr"
                    style="width: 100%"
                    :clearable="false"
                    :disabled="item.checkStatus == 2 || item.checkStatus == 3 || panduan() == 1"
                    @changeValue="item.departmentId = $event"
                    @changeValueArr="item.deptIdArr = $event"
                  />
                </li>
                <li>
                  <div class="out-box">
                    <div>
                      <el-checkbox
                        v-model="item.isGoOut"
                        :disabled="
                          item.checkStatus == 2 ||
                          item.checkStatus == 3 ||
                          (panduan() == 1 && isShowSubmit == 4)
                        "
                        @change="onOutSwitchChange(item, index, $event, 'organization')"
                        >有</el-checkbox
                      >
                    </div>
                    <el-link
                      v-if="item.isGoOut"
                      class="out-a"
                      type="warning"
                      @click="openOutReportDialog(item, index, 'organization')"
                      >查看报告</el-link
                    >
                  </div>
                </li>
                <li>
                  <el-input
                    v-model="item.hour"
                    @input="v => (item.hour = v.replace(/[^\d.]/g, ''))"
                    :disabled="item.checkStatus == 2 || item.checkStatus == 3 || panduan() == 1"
                  >
                  </el-input>
                </li>
                <li>
                  <el-input
                    v-model="item.workContent"
                    type="textarea"
                    :disabled="item.checkStatus == 2 || item.checkStatus == 3 || panduan() == 1"
                    :autosize="{ minRows: 4, maxRows: 5 }"
                  >
                  </el-input>
                </li>
                <li style="transform: translateY(10px)">
                  <el-button
                    type="danger"
                    size="mini"
                    icon="el-icon-delete"
                    circle
                    :disabled="
                      item.checkStatus == 2 ||
                      item.checkStatus == 3 ||
                      isShowSubmit != 4 ||
                      panduan() == 1
                    "
                    @click="handleDeleteItem(index)"
                  >
                  </el-button>
                </li>
              </ul>
            </div>
          </el-scrollbar> -->
          <!-- <div class="add_btn" v-if="isShowSubmit == 4 && !onlyProjectHour"> -->
          <div class="add_btn" v-if="isShowSubmit == 4">
            <p @click="handleAddItem">
              <i class="el-icon-circle-plus-outline add_ico"></i>
              <span>新增加组织级工时</span>
            </p>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          class="teal_bg"
          type="success"
          size="small"
          v-if="!panduan() && isShowSubmit == 4"
          :loading="loadSubmit"
          @click="handleSubmitRule"
        >
          提交
        </el-button>
        <el-button
          class="teal_bg"
          type="success"
          size="small"
          v-if="panduan() && isShowSubmit == 4"
          :loading="loadSave"
          @click="withdraw"
        >
          撤回
        </el-button>
        <el-button
          class="white_bg"
          size="small"
          :loading="loadSubmit"
          v-if="!panduan() && isShowSubmit == 4"
          @click="handleSave"
          >暂存</el-button
        >
        <el-button type="info" class="lightGrey_bg" size="small" @click="handleClose"
          >关闭</el-button
        >
      </span>
    </el-dialog>

    <EditDialog
      width="45%"
      :isShow.sync="isOutReportDialogShow"
      :isReturn="true"
      @closeFn="onOuReportDialogClose"
    >
      <div slot="title" class="title">重要活动报告</div>
      <div slot="content" class="content" style="min-height: 480px; padding-left: 20px">
        <el-form
          style="width: 100%"
          :model="outReportData"
          :rules="outReportRules"
          ref="outReportForm"
          label-width="107px"
          label-position="left"
        >
          <el-form-item label="报告类型" prop="type">
            <el-radio-group
              v-model="outReportData.type"
              :disabled="panduan() == 1 && isShowSubmit == 4"
            >
              <el-radio label="WRITTEN">书面报告</el-radio>
              <el-radio label="ORAL">口头报告</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="活动开始时间"
            style="width: 50%; display: inline-block"
            prop="startDate"
          >
            <el-date-picker
              v-model="outReportData.startDate"
              align="right"
              type="date"
              placeholder="选择日期"
              :picker-options="timePickerOptions"
              :disabled="panduan() == 1 && isShowSubmit == 4"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item
            label="活动结束时间"
            style="width: 50%; display: inline-block"
            prop="endDate"
          >
            <el-date-picker
              v-model="outReportData.endDate"
              align="right"
              type="date"
              placeholder="选择日期"
              :picker-options="endDateOptions"
              :disabled="panduan() == 1 && isShowSubmit == 4"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="活动地点" prop="place">
            <el-input
              v-model.trim="outReportData.place"
              :disabled="panduan() == 1 && isShowSubmit == 4"
            ></el-input>
          </el-form-item>
          <el-form-item :error="outReportData.fileErrMsg">
            <template slot="label">
              <template> 附件</template>
            </template>
            <div class="upload-btn-box">
              <div class="icon-out">
                <Upload
                  v-if="canGoOutSubmit && panduan() == 0 && isShowSubmit == 4"
                  type="WCHDBG"
                  :fields="fields"
                  @upload="upload"
                ></Upload>
              </div>

              <div v-if="canGoOutSubmit">
                <el-button
                  type="success"
                  size="small"
                  style="margin-left: 50px"
                  v-if="outReportData.type == 'WRITTEN'"
                  @click="downloadFile"
                  >下载重要活动报告模板</el-button
                >
                <el-alert
                  style="margin-left: 50px"
                  v-else
                  title="如有微信截图，请在附件上传中上传"
                  type="info"
                >
                </el-alert>
              </div>
            </div>

            <div
              v-for="(n, index) in outReportData.manhourFileList"
              :key="index"
              style="margin-top: 5px"
              class="file-list"
            >
              <a
                class="fj-text file-name"
                target="_blank"
                style="text-decoration: revert; color: #409eff; padding-right: 8px"
                :href="`${filepath}${n.filePath}`"
              >
                {{ n.fileName }}
              </a>

              <i
                v-show="canGoOutSubmit && panduan() == 0 && isShowSubmit == 4"
                @click="del(index)"
                class="iconfont iconerror icon-out"
              ></i>
            </div>
          </el-form-item>
          <el-form-item label="活动说明" prop="content" style="width: 100%">
            <template slot="label">
              活动说明
              <el-tooltip class="item" effect="light" placement="top">
                <div slot="content" style="font-size: 18px; color: #000">
                  编写提示：<br />
                  一、汇报事由：XX时间参与了XX组织的XX活动，活动主题是XXX，同时参加活动的还有XX单位/企业，
                  <br />　　我(或我司XX)以XX角色（汇报者或参会人员等）在活动中作出了汇报（或其他事项）。<br />
                  二、梳理活动主要情况，汇报需要特别关注的重点事项。<br />
                  三、结合实际，针对重点问题提出切实可行的建议。
                </div>
                <i class="el-icon-question"></i>
              </el-tooltip>
            </template>
            <el-input
              type="textarea"
              v-model.trim="outReportData.content"
              :readonly="panduan() == 1 && isShowSubmit == 4"
              :autosize="{ minRows: 8, maxRows: 9999 }"
              placeholder="编写提示：
一、汇报事由：XX时间参与了XX组织的XX活动，活动主题是XXX，同时参加活动的还有XX单位/企业，我(或我司XX)以XX角色（汇报者或参会人员等）在活动中作出了汇报（或其他事项）。
二、梳理活动主要情况，汇报需要特别关注的重点事项。
三、结合实际，针对重点问题提出切实可行的建议。"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div
        slot="footer"
        class="footer"
        v-if="canGoOutSubmit && panduan() == 0 && isShowSubmit == 4"
      >
        <el-button type="primary" plain @click="saveOutReport">保存</el-button>
        <el-button type="danger" plain @click="deleteOutReport" v-if="isDetailInDialog"
          >删除</el-button
        >
        <el-button type="info" plain @click="onOuReportDialogClose">关闭</el-button>
      </div>
      <div slot="footer" class="footer" v-else>
        <el-button type="info" plain @click="onOuReportDialogClose">关闭</el-button>
      </div>
    </EditDialog>
  </div>
</template>

<script>
import { dateFormater } from '@/util/common'
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),
  },
  components: {
    EditDialog: () => import('@/components/EditDialog.vue'),
    Upload: () => import('@/components/upload/Upload.vue'),
    DeptCascader: () => import('@/components/dept/DeptCascader.vue'),
  },
  props: {
    isEditDlg: {
      type: Boolean,
      default: false,
    },
    isShowSubmit: {
      type: Number,
      default: 4,
    },
    getItemData: {
      type: Object,
      default: () => {
        return {}
      },
    },
    getDate: {
      type: String,
      default: '',
    },
    getStr: {
      type: String,
      default: '',
    },
    fatherMethod: {
      type: Function,
      default: null,
    },
    ZHB_projectManager: {
      type: Array,
      default: [],
    },
    ZHB_member: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      searchProjectName: '',
      userId: JSON.parse(localStorage.getItem('userInfo')).id, // 登录用户 id
      loading: false, // 加载
      projectList: [], // 参与项目 列表数据
      workList: [], // 工作 列表数据
      formData: [], // 保存数据
      loadSave: false, // 撤回 按钮 -- 加载
      loadSubmit: false, // 提交/保存 按钮 -- 加载
      isSubmit: false, // 判断 是否 已提交
      isSave: true, // 判断 是否 已保存
      checkStatus: 0, //判断 是否 该项目是否提交过  //0暂存，1提交，2不通过，3通过，4记录删除
      listDept: [], // 所有组织列表
      selectIdsArr: [],
      depts: [], // 所有部门列表getDate

      onlyProjectHour: false,
      onlyOrgHour: false,
      isZHB: false,
      isShowAll: false,

      isOutReportDialogShow: false,
      nowClickProjectHourIndex: '', //当前点击的项目级工时下标，用来赋值
      outReportRules: {
        type: [{ required: true, message: '请选择报告类型', trigger: 'change' }],
        startDate: [{ type: 'date', required: true, message: '请选择日期', trigger: 'change' }],
        endDate: [{ type: 'date', required: true, message: '请选择日期', trigger: 'change' }],
        place: [{ required: true, message: '请输入活动地点', trigger: 'blur' }],
        content: [{ required: true, message: '请填写活动说明', trigger: 'blur' }],
      },
      isDetailInDialog: false, //用于标识当前外出活动弹框是新增弹框还是详情弹框
      outReportType: '', //用于判断当前外出活动报告对话框是项目组还是组织级
      canGoOutSubmit: true,
      //外出报告弹框数据源
      outReportData: {
        type: 'WRITTEN', //类型
        startDate: '', //实际开始时间
        endDate: '', //实际结束时间
        place: '',
        content: '',
        manhourFileList: [],
        deleteManhourFileIdList: [],
        fileErrMsg: '', //附件上传校验文字
      },

      filepath: process.env.VUE_APP_FILEPATH,
      fields: {
        name: 'fileName',
        path: 'filePath',
        type: 'type',
      },
      // 日期选择器配置快捷方式
      timePickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        },
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              picker.$emit('pick', new Date())
            },
          },
          {
            text: '昨天',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24)
              picker.$emit('pick', date)
            },
          },
          {
            text: '一周前',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', date)
            },
          },
        ],
      },
      endDateOptions: {
        disabledDate: time => {
          const startDate = new Date(this.outReportData.startDate)
          if (startDate) {
            return time.getTime() < startDate.getTime()
          } else {
            return false
          }
        },
      },
      deptList: [],
      deptNameList: [],
    }
  },
  created() {
    this.$api.sysDept
      .listDept()
      .then(res => {
        this.deptNameList = res.data
      })
      .catch(err => {
        console.log(err)
      })
  },
  mounted() {
    this.getHourDetailedData() /** 加载 "项目 填报工时" 详情数据 **/
    this.getlistDept() /** 加载 所有组织数据  **/
    // this.getUserAuthority()

    if (this.isShowSubmit == 1) {
      this.$message('未来工时不能填报')
    } else if (this.isShowSubmit == 2) {
      this.$message('今日已超出最晚可填报时间12：00')
    } else if (this.isShowSubmit == 3) {
      this.$message('过去工时不能填报')
    }
  },
  methods: {
    //* 判断该成员在是否是综合部成员，确在综合部扮演什么角色
    getUserAuthority() {
      // 确定部门，结果为true说明该角色是综合部成员
      const deptResult = this.ZHB_member.some(item => this.userInfo.staffId == item)
      if (deptResult) {
        this.isZHB = true
        const staffResult = this.ZHB_projectManager.some(item => item == this.userInfo.staffId)
        if (staffResult) {
          // 说明这个人是综合部部门经理，只能显示组织级工时，隐藏项目级工时
          this.handleAddItem()
          this.onlyProjectHour = false
          this.onlyOrgHour = true
        } else {
          // 这个人是综合部普通成员
          this.onlyProjectHour = true
          this.onlyOrgHour = false
        }
      } else {
        this.isZHB = false
        this.onlyProjectHour = false
        this.onlyOrgHour = false
      }
    },
    /** 加载 "项目 填报工时" 详情数据 **/
    getHourDetailedData() {
      this.loading = true
      this.manhourDate = this.getDate
      this.$api.hour
        .getManhourByDate(this.getDate)
        .then(res => {
          if (res.data.length > 0) {
            // this.loading = false
            let _project = []
            let _work = []
            for (let i = 0; i < res.data.length; i++) {
              if (res.data[i].manhourType == 0) {
                _project.push({
                  ...res.data[i],
                  manhourId: res.data[i].id,
                  isGoOut: res.data[i].outgoingType == 'HAVE' ? true : false,
                  outReportType: 'project',
                })
              } else if (res.data[i].manhourType == 1) {
                _work.push({
                  ...res.data[i],
                  type: '组织级',
                  manhourId: res.data[i].id,
                  isGoOut: res.data[i].outgoingType == 'HAVE' ? true : false,
                  outReportType: 'organization',
                })
              }
            }
            this.projectList = _project
            this.workList = _work
            // // 该用户是综合部成员
            // if (this.isZHB) {
            //   this.isShowAll = false
            //   if (this.onlyOrgHour && this.projectList.length !== 0) {
            //     // 该成员是综合部组长经理，但是又曾经填写了项目级工时
            //     this.isShowAll = true
            //   }
            //   if (this.onlyProjectHour && this.workList.length !== 0) {
            //     this.isShowAll = true
            //   }
            // }

            this.getPartakeProjectData(_project) /** 加载 "参与项目" 列表数据 **/
          } else {
            this.getPartakeProjectData() /** 加载 "参与项目" 列表数据 **/
          }
        })
        .catch(err => {
          console.log(err)
        })
    },

    //整理返回回来的项目列表与排序列表
    OrganizeProList(prolist, proIdList) {
      let arr = []
      if (prolist && prolist.length > 0) {
        prolist.forEach(item => {
          let index = proIdList.findIndex(id => id == item.id)
          if (index !== -1) {
            //说明当前项目id在排序列表中存在，直接将item内容替换到排序列表在
            proIdList.splice(index, 1, item)
          } else {
            //如果没有找到，说明该项目是新项目，插入到排序列表最前
            proIdList.unshift(item)
          }
        })
        //再次渲染排序列表查看当前是否存在id
        proIdList = proIdList.filter(item => typeof item == 'object')
        arr = [...proIdList]
      }
      return arr
    },

    /**
     * 加载 "参与项目" 列表数据
     * @param list     "参与项目" 数组
     */
    getPartakeProjectData(list) {
      this.$api.hour
        .listProjectToManhour()
        .then(async res => {
          //获取排序数据
          const idRes = await this.$api.projectMemberSort.selectDetailStaffId()
          const proIdList = idRes.data?.projectSortList || []
          res.data = this.OrganizeProList(res.data, proIdList)
          let _cyxm = [] // 创建 "参与项目" 数组
          // c 的长度为2，参与的所有项目
          for (let c = 0; c < res.data.length; c++) {
            _cyxm.push({
              ...res.data[c],
              userId: this.userId,
              workName: res.data[c].projectName,
              type: '项目级',
              manhourType: 0,
              workContent: '9：00-18：30 \n1、\n2、\n3、',
              id: '',
              //预计开始时间和实际开始时间得最早，预计结束和实际结束得最晚时间
              //预计项目开始时间 projectPredictStartDate;
              //预计结束时间 projectPredictEndDate;
              //实际项目开始时间 actualPredictStartDate;
              // 实际完成日期 accomplishDate;
              begin_time:
                res.data[c].projectPredictStartDate <= res.data[c].actualPredictStartDate
                  ? res.data[c].projectPredictStartDate == null
                    ? '暂定'
                    : dateFormater('YYYY-MM-DD', res.data[c].projectPredictStartDate)
                  : dateFormater('YYYY-MM-DD', res.data[c].actualPredictStartDate),
              end_time:
                res.data[c].projectPredictEndDate >= res.data[c].accomplishDate
                  ? res.data[c].projectPredictEndDate == null
                    ? '暂定'
                    : dateFormater('YYYY-MM-DD', res.data[c].projectPredictEndDate)
                  : dateFormater('YYYY-MM-DD', res.data[c].accomplishDate),
              projectId: res.data[c].id,
            })
          }

          //list 的长度为1，是填报过的工时
          if (list && list.length > 0) {
            for (let i = 0; i < list.length; i++) {
              const e = list[i]
              for (let item = 0; item < _cyxm.length; item++) {
                // i,是参与所有项目。e是已填报的项目
                const i = _cyxm[item]
                if (i.projectId == e.projectId) {
                  i.projectId = e.projectId
                  i.id = e.id
                  i.workContent = e.workContent
                  i.checkStatus = e.checkStatus
                  i.hour = e.hour
                  i.manhourId = e.manhourId
                  i.isGoOut = e.isGoOut
                  i.outgoingType = e.outgoingType
                  i.outReportType = e.outReportType
                  i.manhourOutgoing = e.manhourOutgoing
                }
              }
            }
            this.projectList = Object.assign([], _cyxm)
            this.formData = list.concat(this.workList)
          } else {
            this.projectList = _cyxm
          }
          this.loading = false
        })
        .catch(err => {
          console.log(err)
        })
    },

    /**
     * 点击 "组织级类型工时 -- 列表 - 删除" 图标
     * @param index     下标值
     *
     */
    handleDeleteItem(index) {
      if (this.workList[index].checkStatus || this.workList[index].checkStatus == 0) {
        this.workList[index].checkStatus = 4
      } else {
        this.workList.splice(index, 1)
      }
    },

    /** 点击 "新增加组织级工时" 按钮 **/
    handleAddItem() {
      if (this.panduan()) {
        this.$message('有内容提交后未审核，请先撤回')
        return
      }

      this.workList.push({
        id: '', // id
        workName: '', // 工作名称
        hour: '', // 工时
        type: '组织级', // 类型
        manhourType: 1, // 类型key
        workContent: '9：00-18：30 \n1、\n2、\n3、', // 工作内容
        manhourDate: new Date(this.getDate).getTime(), // 填报时间
        userId: JSON.parse(localStorage.getItem('userInfo')).id, // 登录用户 id
        outgoingType: 'NO',
        isGoOut: false,
        deptId: '',
        deptIdArr: [],
      })
      this.handleScrollBottom()
    },

    /** 点击 "暂存" 按钮 **/
    handleSave() {
      if (this.getItemData.itemData == 2) {
        this.$message('只允许填报七天以前的工时')
        return
      }
      //填报规则检验
      let time = 0
      for (let l = 0; l < this.projectList.length; l++) {
        const e = this.projectList[l]
        if (e.hour || e.hour == '0') {
          e.haveHour = true
        } else {
          e.haveHour = false
        }
        if (e.haveHour) {
          time += Number(e.hour)
        }

        if (e.haveHour) {
          if (!e.workContent) {
            this.$message({
              type: 'error',
              message: '填了工时的工作内容不能为空',
            })
            return
          }
        }
      }
      const departmentIdArr = []
      for (let l = 0; l < this.workList.length; l++) {
        const e = this.workList[l]
        if (e.hour || e.hour == '0') {
          e.haveHour = true
        } else {
          e.haveHour = false
        }
        if (e.haveHour) {
          time += Number(e.hour)
        }
        if (e.haveHour) {
          if (!e.workContent) {
            this.$message({
              type: 'error',
              message: '填了工时的工作内容不能为空',
            })
            return
          }
        }
        if (!e.deptId) {
          this.$message({
            type: 'error',
            message: '组织级工作名称不能为空',
          })
          return
        }
        departmentIdArr.push(e.deptId)
      }
      function hasDuplicate(arr) {
        return new Set(arr).size !== arr.length
      }

      if (hasDuplicate(departmentIdArr)) {
        this.$message({
          type: 'error',
          message: '相同部门组织级工时不能重复',
        })
        return
      }
      if (time > 24) {
        this.$message({
          type: 'error',
          message: '当天工作时长不能大于24个小时',
        })
        return
      }
      let tips = ''
      //判断是否填写了内容
      const hourArr = [...this.projectList, ...this.workList]
      const findResult = hourArr.find(item => item.haveHour)
      if (findResult) {
        // 填写了工时
        if (time == 0) {
          tips = '当日仅填报工时0小时，确认暂存？'
          this.$confirm(tips, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
            .then(() => {
              this.handleTempSave()
            })
            .catch(() => {
              this.$message({
                type: 'info',
                message: '已取消',
              })
            })
        } else {
          this.handleTempSave()
        }
      } else {
        // 未填写任何工时
        this.$message({
          type: 'error',
          message: '当天未填写任何工时',
        })
        return
      }
    },
    handleTempSave() {
      let _project = []
      let _work = []

      for (let i = 0; i < this.projectList.length; i++) {
        _project.push({
          id: this.projectList[i].id,
          hour: this.projectList[i].hour,
          projectId: this.projectList[i].projectId,
          manhourDate: new Date(this.getDate).getTime(),
          workContent: this.projectList[i].workContent,
          workName: this.projectList[i].workName,
          manhourType: 0,
          outgoingType: this.projectList[i].isGoOut ? 'HAVE' : 'NO',
          manhourOutgoing: this.projectList[i].manhourOutgoing,
          deleteOutgoingIdList: this.projectList[i].deleteOutgoingIdList,
          checkStatus:
            this.projectList[i].checkStatus == 1 ||
            this.projectList[i].checkStatus == 2 ||
            this.projectList[i].checkStatus == 3
              ? this.projectList[i].checkStatus
              : 0, // 保存状态
          userId: JSON.parse(localStorage.getItem('userInfo')).id, // 登录用户 id
        })
      }

      for (let i = 0; i < this.workList.length; i++) {
        _work.push({
          id: this.workList[i].id,
          deptId: this.workList[i].deptId,
          deptIdArr: this.workList[i].deptIdArr,
          departmentId: this.workList[i].deptId,
          hour: this.workList[i].hour,
          projectId: this.workList[i].projectId,
          manhourDate: new Date(this.getDate).getTime(),
          workContent: this.workList[i].workContent,
          workName: this.getUserDeptName(this.workList[i].deptId),
          manhourType: 1,
          outgoingType: this.workList[i].isGoOut ? 'HAVE' : 'NO',
          manhourOutgoing: this.workList[i].manhourOutgoing,
          deleteOutgoingIdList: this.workList[i].deleteOutgoingIdList,
          checkStatus:
            this.workList[i].checkStatus == 1 ||
            this.workList[i].checkStatus == 2 ||
            this.workList[i].checkStatus == 3 ||
            this.workList[i].checkStatus == 4
              ? this.workList[i].checkStatus
              : 0, // 保存状态
          companyType: this.workList[i].companyType, //组织名称
          userId: JSON.parse(localStorage.getItem('userInfo')).id, // 登录用户 id
        })
      }

      let _concat = _project.concat(_work)
      this.formData = _concat
      this.loadSubmit = true
      this.loading = true

      this.$api.hour
        .saveBatchManhour(_concat)
        .then(res => {
          this.loadSubmit = false
          this.loading = false
          this.$message({
            message: '保存成功',
            type: 'success',
          })

          this.handleClose() /** 关闭 “弹出框” **/
          this.getHourDetailedData() /** 加载 "项目 填报工时" 详情数据 **/

          /** 调用 父组件 方法 > 项目工时 **/
          this.fatherMethod(new Date(this.getDate).format('yyyy-MM'))
        })
        .catch(err => {
          this.loadSubmit = false
          this.loading = false
          console.log(err)
        })
    },
    /** 点击 "提交" 按钮得验证 **/
    handleSubmitRule() {
      if (this.getItemData.itemData == 2) {
        this.$message('只允许填报七天以前的工时')
        return
      }
      //填报规则检验
      let time = 0
      for (let l = 0; l < this.projectList.length; l++) {
        const e = this.projectList[l]
        if (e.hour || e.hour == '0') {
          e.haveHour = true
        } else {
          e.haveHour = false
        }
        if (e.haveHour && e.checkStatus != 4) {
          time += Number(e.hour)
        }
        if (e.haveHour) {
          if (!e.workContent) {
            this.$message({
              type: 'error',
              message: '填了工时的工作内容不能为空',
            })
            return
          }
        }
      }

      let departmentIdArr = []
      for (let l = 0; l < this.workList.length; l++) {
        const e = this.workList[l]
        if (e.hour || e.hour == '0') {
          e.haveHour = true
        } else {
          e.haveHour = false
        }
        if (e.haveHour && e.checkStatus != 4) {
          time += Number(e.hour)
        }
        if (e.haveHour) {
          if (!e.workContent) {
            this.$message({
              type: 'error',
              message: '填了工时的工作内容不能为空',
            })
            return
          }
        }
        if (!e.deptId) {
          this.$message({
            type: 'error',
            message: '组织级工作名称不能为空',
          })
          return
        }
        departmentIdArr.push(e.deptId)
      }
      function hasDuplicate(arr) {
        return new Set(arr).size !== arr.length
      }

      if (hasDuplicate(departmentIdArr)) {
        this.$message({
          type: 'error',
          message: '相同部门组织级工时不能重复',
        })
        return
      }

      if (time > 24) {
        this.$message({
          type: 'error',
          message: '当天工作时长不能大于24个小时',
        })
        return
      }

      let tips = ''

      //判断是否填写了内容
      const hourArr = [...this.projectList, ...this.workList]
      const findResult = hourArr.find(item => item.haveHour)
      if (findResult) {
        // 填写了工时
        if (time > 12) {
          tips = '当日填报工时已超过12小时，确认提交？'
          this.$confirm(tips, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
            .then(() => {
              this.handleSubmit()
            })
            .catch(() => {
              this.$message({
                type: 'info',
                message: '已取消',
              })
            })
        } else if (time == 0) {
          tips = '当日仅填报工时0小时，确认提交？'
          this.$confirm(tips, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
            .then(() => {
              this.handleSubmit()
            })
            .catch(() => {
              this.$message({
                type: 'info',
                message: '已取消',
              })
            })
        } else {
          this.handleSubmit()
        }
      } else {
        // 未填写任何工时
        this.$message({
          type: 'error',
          message: '当天未填写任何工时',
        })
        return
      }
    },
    //提交
    handleSubmit() {
      let _project = []
      let _work = []

      for (let i = 0; i < this.projectList.length; i++) {
        _project.push({
          id: this.projectList[i].id,
          hour: this.projectList[i].hour,
          projectId: this.projectList[i].projectId,
          manhourDate: new Date(this.getDate).getTime(),
          workContent: this.projectList[i].workContent,
          workName: this.projectList[i].workName,
          outgoingType: this.projectList[i].isGoOut ? 'HAVE' : 'NO',
          manhourOutgoing: this.projectList[i].manhourOutgoing,
          deleteOutgoingIdList: this.projectList[i].deleteOutgoingIdList,
          manhourType: 0,
          checkStatus:
            this.projectList[i].checkStatus == 2 || this.projectList[i].checkStatus == 3
              ? this.projectList[i].checkStatus
              : 1, // 保存状态
          userId: JSON.parse(localStorage.getItem('userInfo')).id, // 登录用户 id
        })
      }

      for (let i = 0; i < this.workList.length; i++) {
        _work.push({
          id: this.workList[i].id,
          deptId: this.workList[i].deptId,
          deptIdArr: this.workList[i].deptIdArr,
          departmentId: this.workList[i].deptId,
          hour: this.workList[i].hour,
          projectId: this.workList[i].projectId,
          manhourDate: new Date(this.getDate).getTime(),
          workContent: this.workList[i].workContent,
          outgoingType: this.workList[i].isGoOut ? 'HAVE' : 'NO',
          manhourOutgoing: this.workList[i].manhourOutgoing,
          deleteOutgoingIdList: this.workList[i].deleteOutgoingIdList,
          workName: this.getUserDeptName(this.workList[i].deptId),
          manhourType: 1,
          checkStatus:
            this.workList[i].checkStatus == 4 ||
            this.workList[i].checkStatus == 2 ||
            this.workList[i].checkStatus == 3
              ? this.workList[i].checkStatus
              : 1, // 保存状态
          companyType: this.workList[i].companyType, //组织名称
          userId: JSON.parse(localStorage.getItem('userInfo')).id, // 登录用户 id
        })
      }
      let _concat = _project.concat(_work)

      this.$confirm('已提交的记录被审核后将不能再修改内容，请确认是否提交', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.loadSubmit = true
          this.loading = true
          this.$api.hour
            .saveBatchManhour(_concat)
            .then(res => {
              this.loadSubmit = false
              this.loading = false
              this.$message({
                message: '提交成功',
                type: 'success',
              })

              this.handleClose() /** 关闭 “弹出框” **/
            })
            .catch(err => {
              this.loadSubmit = false
              this.loading = false
              console.log(err)
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消',
          })
          this.loadSubmit = false
          this.loading = false
        })
    },

    /** 撤回 提交 **/
    withdraw() {
      let _project = []
      let _work = []

      for (let i = 0; i < this.projectList.length; i++) {
        _project.push({
          id: this.projectList[i].id,
          hour: this.projectList[i].hour,
          projectId: this.projectList[i].projectId,
          manhourDate: new Date(this.getDate).getTime(),
          workContent: this.projectList[i].workContent,
          workName: this.projectList[i].workName,
          manhourType: 0,
          outgoingType: this.projectList[i].isGoOut ? 'HAVE' : 'NO',
          manhourOutgoing: this.projectList[i].manhourOutgoing,
          deleteOutgoingIdList: this.projectList[i].deleteOutgoingIdList,
          checkStatus:
            !this.projectList[i].checkStatus ||
            this.projectList[i].checkStatus == 1 ||
            this.projectList[i].checkStatus == 0
              ? 0
              : this.projectList[i].checkStatus, // 保存状态
          userId: JSON.parse(localStorage.getItem('userInfo')).id, // 登录用户 id
        })
      }

      for (let i = 0; i < this.workList.length; i++) {
        _work.push({
          id: this.workList[i].id,
          deptId: this.workList[i].deptId,
          deptIdArr: this.workList[i].deptIdArr,
          departmentId: this.workList[i].deptId,
          hour: this.workList[i].hour,
          projectId: this.workList[i].projectId,
          manhourDate: new Date(this.getDate).getTime(),
          workContent: this.workList[i].workContent,
          workName: this.getUserDeptName(this.workList[i].deptId),
          manhourType: 1,
          outgoingType: this.workList[i].isGoOut ? 'HAVE' : 'NO',
          manhourOutgoing: this.workList[i].manhourOutgoing,
          deleteOutgoingIdList: this.workList[i].deleteOutgoingIdList,
          checkStatus:
            !this.workList[i].checkStatus ||
            this.workList[i].checkStatus == 1 ||
            this.workList[i].checkStatus == 0
              ? 0
              : this.workList[i].checkStatus, // 保存状态
          userId: JSON.parse(localStorage.getItem('userInfo')).id, // 登录用户 id
          companyType: this.workList[i].companyType, //组织名称
        })
      }

      let _concat = _project.concat(_work)

      this.$confirm('确定撤回当天工时?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.loadSave = true
          this.loading = true
          this.$api.hour
            .saveBatchManhour(_concat)
            .then(res => {
              this.loadSave = false
              this.loading = false
              this.$message({
                message: '撤回成功',
                type: 'success',
              })

              this.handleClose() /** 关闭 “弹出框” **/
            })
            .catch(err => {
              this.loadSave = false
              this.loading = false
              console.log(err)
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消',
          })
          this.loadSave = false
          this.loading = false
        })
    },

    /** 关闭 “弹出框” **/
    handleClose() {
      this.formData = {}
      this.$emit('childEvent', false)
    },
    // 滚动条置底
    handleScrollBottom() {
      this.$nextTick(() => {
        this.$refs.leftScrollbar.wrap.scrollTop = this.$refs.leftScrollbar.wrap.scrollHeight
      })
    },
    getlistDept() {
      // this.$api.hour
      //   .organizationManhourType()
      //   .then(res => {
      //     this.listDept = res.data
      //   })
      //   .catch(err => {
      //     console.log(err)
      //   })

      this.$api.sysDept
        .getUserDeptTree(this.userInfo.staffId)
        .then(res => {
          this.deptList = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },
    /** 判断是否提交状态 */
    panduan() {
      let i = 0
      this.projectList.forEach(e => {
        if (e.checkStatus == 1) {
          i = 1
        }
      })
      this.workList.forEach(e => {
        if (e.checkStatus == 1) {
          i = 1
        }
      })

      return i
    },
    onChangeWork() {
      this.selectIdsArr = []
      for (const item of this.workList) {
        if (item.checkStatus == 4) {
          break
        } else if (item.companyType) {
          this.selectIdsArr.push(item.companyType)
        }
      }
    },
    getUserDeptName(id) {
      const dept = this.deptNameList.find(v => v.id === id)
      if (dept) {
        return dept.deptName
      } else {
        return ''
      }
    },

    // 当外出开关change事件触发打开对话框
    onOutSwitchChange(item, index, e, type) {
      this.canGoOutSubmit = true
      let defaultDate = new Date(this.getDate)
      this.outReportData = {
        type: 'WRITTEN',
        startDate: defaultDate,
        endDate: defaultDate,
        place: '',
        content: '',
        manhourFileList: [],
        deleteManhourFileIdList: [],
        fileErrMsg: '',
      }
      this.outReportData.addType = type
      this.outReportData.manhourIndex = index
      // 勾选外出复选框
      if (e) {
        this.isDetailInDialog = false
        this.isOutReportDialogShow = true
      } else {
        // 取消勾选复选框，如果当前行有外出信息，则弹出提示框
        if (item.manhourOutgoing) {
          this.$confirm('取消勾选将删除该重要活动报告, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
            .then(() => {
              if (item.manhourOutgoing.id) {
                // 如果该外出报告存在id，则需要存储被删除的id
                item.deleteOutgoingIdList = []
                item.deleteOutgoingIdList.push(item.manhourOutgoing.id)
              }
              item.manhourOutgoing = null
            })
            .catch(() => {
              if (type == 'project') {
                this.projectList[this.outReportData.manhourIndex].isGoOut = true
              } else if (type == 'organization') {
                this.workList[this.outReportData.manhourIndex].isGoOut = true
              }
            })
        }
      }
    },

    // 当关闭对话框前，关闭当前弹框，并重置开关
    onOuReportDialogClose() {
      // 如果是点击查看详情出现的弹框，点击关闭时无需重置开关状态
      if (!this.isDetailInDialog) {
        if (this.outReportData.addType == 'project') {
          this.projectList[this.outReportData.manhourIndex].isGoOut = false
        } else if (this.outReportData.addType == 'organization') {
          this.workList[this.outReportData.manhourIndex].isGoOut = false
        }
      }
      this.outReportData = {}
      this.isOutReportDialogShow = false
    },

    upload(r) {
      this.outReportData.manhourFileList.push(...r)
      this.outReportData.fileErrMsg = ''
    },

    del(index) {
      if (this.outReportData.manhourFileList[index].id) {
        this.outReportData.deleteManhourFileIdList.push(
          this.outReportData.manhourFileList[index].id
        )
      }
      this.outReportData.manhourFileList = this.outReportData.manhourFileList.filter(
        (item, i) => i != index
      )
    },

    saveOutReport() {
      // 触发校验
      this.$refs.outReportForm.validate(valid => {
        if (valid) {
          //  && this.outReportData.manhourFileList.length > 0
          if (this.outReportData.manhourFileList) {
            if (this.outReportData.addType == 'project') {
              this.projectList[this.outReportData.manhourIndex].manhourOutgoing = {
                ...this.outReportData,
              }
            } else if (this.outReportData.addType == 'organization') {
              this.workList[this.outReportData.manhourIndex].manhourOutgoing = {
                ...this.outReportData,
              }
            }
            this.isOutReportDialogShow = false
            this.outReportData = {}
          }
          // else {
          //   this.outReportData.fileErrMsg = '请上传附件'
          // }
        }
        // else {
        //   if (this.outReportData.manhourFileList && this.outReportData.manhourFileList.length > 0) {
        //     return false
        //   } else {
        //     this.outReportData.fileErrMsg = '请上传附件'
        //   }
        //   return false
        // }
      })
    },

    openOutReportDialog(item, index, type) {
      if (item.manhourOutgoing.id) {
        // 说明该外出报告已被存储，记录当前打开详情对应的工时id
        this.canGoOutSubmit = true
        if (item.checkStatus == 2 || item.checkStatus == 3) {
          this.canGoOutSubmit = false
        }
        this.isDetailInDialog = true //是详情弹框，关闭时无需重置按钮
        this.outReportData = { ...item.manhourOutgoing }
        this.outReportData.editStatus = 'edit'
        this.outReportData.addType = type
        this.outReportData.manhourIndex = index
        this.outReportData.deleteManhourFileIdList
          ? this.outReportData.deleteManhourFileIdList
          : (this.outReportData.deleteManhourFileIdList = [])
        this.isOutReportDialogShow = true
      } else {
        // 如果没有id说明是新增的，通过下标传入对象
        this.isDetailInDialog = true //是详情弹框，关闭时无需重置按钮
        this.outReportData = { ...item.manhourOutgoing }
        this.outReportData.editStatus = 'add'
        this.outReportData.addType = type
        this.outReportData.manhourIndex = index
        this.isOutReportDialogShow = true
      }
    },

    deleteOutReport() {
      if (this.outReportData.addType == 'project') {
        if (this.outReportData.id) {
          // 说明是数据库中已存储的外出报告，需要删除id
          this.projectList[this.outReportData.manhourIndex].deleteOutgoingIdList = []
          this.projectList[this.outReportData.manhourIndex].deleteOutgoingIdList.push(
            this.outReportData.id
          )
        }
        this.projectList[this.outReportData.manhourIndex].manhourOutgoing = null
        this.projectList[this.outReportData.manhourIndex].isGoOut = false
      } else if (this.outReportData.addType == 'organization') {
        if (this.outReportData.id) {
          // 说明是数据库中已存储的外出报告，需要删除id
          this.workList[this.outReportData.manhourIndex].deleteOutgoingIdList = []
          this.workList[this.outReportData.manhourIndex].deleteOutgoingIdList.push(
            this.outReportData.id
          )
        }
        this.workList[this.outReportData.manhourIndex].manhourOutgoing = null
        this.workList[this.outReportData.manhourIndex].isGoOut = false
      }
      this.isOutReportDialogShow = false
      this.outReportData = {}
    },

    async downloadFile() {
      window.location.href =
        'https://pro.huitukj.com/iip-api/profile/OPERATION_MANUAL/template/外出活动报告模板.docx'
    },

    projectQuerySearch(queryString, cb) {
      let projectList = this.projectList.map(item => {
        return { value: item.projectName }
      })
      let results = queryString ? this.projectCreateFilter(queryString) : projectList
      // 调用 callback 返回建议列表的数据
      cb(results)
      window.dispatchEvent(new Event('resize'))
    },

    projectCreateFilter(queryString) {
      let results = this.projectList.map(item => {
        return { value: item.projectName }
      })
      // 将输入的内容进行拆分，返回值为一个数组
      const queryStringList = queryString.split('')
      if (queryStringList.length > 0) {
        queryStringList.forEach(queryItem => {
          let filterResults = results.filter(nameItem => nameItem.value?.includes(queryItem))
          results = [...filterResults]
        })
      }
      return results
    },

    onProkectNameSelect(e) {
      if (e) {
        this.searchProjectName = e.value
        let findIndex = this.projectList.findIndex(
          item => item.projectName == this.searchProjectName
        )
        if (findIndex !== -1) {
          let deleteArr = this.projectList.splice(findIndex, 1)
          this.projectList.unshift(deleteArr[0])
        }
        // this.searchProjectName = ''
      }
      this.$forceUpdate()
    },

    // searchProjectHour() {
    //   let findIndex = this.projectList.findIndex(item => item.projectName == this.searchProjectName)
    //   if (findIndex !== -1) {
    //     let deleteArr = this.projectList.splice(findIndex, 1)
    //     this.projectList.unshift(deleteArr[0])
    //   }
    //   this.searchProjectName = ''
    // },
  },
}
</script>

<style lang="scss" scoped>
.dialog-title {
  text-align: left;
  overflow: hidden;

  .title-text {
    font-size: 20px;
    letter-spacing: 1px;
    color: #4d4d4d;
  }
}

/deep/ .el-dialog__headerbtn .el-dialog__close {
  color: #333333;
}

.date_div {
  position: absolute;
  left: 50%;
  top: -10px;
  font-size: 26px;
  font-weight: bold;
  color: #333333;
  transform: translate(-50%);
}

.form_box {
  position: relative;
  overflow: hidden;
  width: 100%;

  .tips_div {
    margin: 15px 0;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .tips_div_left {
      display: flex;
      align-items: center;
      flex: 1;
    }
  }

  .tips_div_height {
    width: 310px;
    height: 25px;
    line-height: 25px;
    color: red;
  }

  .add_btn {
    margin-top: 16px;

    p {
      position: relative;
      width: 150px;
      height: 27px;
      cursor: pointer;

      .add_ico {
        font-size: 24px;
        color: #014d67;
      }

      span {
        position: absolute;
        top: 2px;
        font-size: 15px;
        color: #999999;
        margin-left: 5px;
      }
    }
  }

  .scrollbar_div {
    height: 420px;

    /deep/ .el-scrollbar__wrap {
      overflow-x: hidden;
    }
  }

  .form_header,
  .form_body {
    display: flex;
    li {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .form_header {
    height: 50px;
    line-height: 50px;
    text-align: center;
    background-color: #edf0f5;
  }

  .form_body {
    text-align: center;
  }

  .project_div {
    .form_header {
      li {
        border-right: 1px solid #ebeef5;
      }

      li:first-child {
        width: 25%;
        border-left: 1px solid #ebeef5;
      }

      li:nth-child(2),
      li:nth-child(3) {
        width: 12%;
      }

      li:nth-child(4),
      li:nth-child(5) {
        width: 10%;
      }

      li:last-child {
        width: 65%;
      }
    }

    .form_body {
      li {
        position: relative;
        width: 25%;
        padding: 10px 5px;
        border-right: 1px solid #ebeef5;
        border-bottom: 1px solid #ebeef5;
        box-sizing: border-box;

        display: flex;
        justify-content: center;
        align-items: center;
      }

      li:first-child {
        width: 25%;
        border-left: 1px solid #ebeef5;
      }

      li:nth-child(2),
      li:nth-child(3) {
        width: 12%;
      }

      li:nth-child(4),
      li:nth-child(5) {
        width: 10%;
      }

      li:last-child {
        width: 65%;
      }
    }
  }
  .work_div_margintop {
    margin-top: 20px;
  }
  .work_div {
    .form_header {
      li {
        border-right: 1px solid #ebeef5;
      }

      li:first-child {
        width: 24%;
        border-left: 1px solid #ebeef5;
      }

      li:nth-child(2),
      li:nth-child(3) {
        width: 15%;
      }

      li:last-child {
        width: 60%;
      }
    }

    .form_body {
      li {
        position: relative;
        width: 25%;
        padding: 10px 5px;
        border-right: 1px solid #ebeef5;
        border-bottom: 1px solid #ebeef5;
        box-sizing: border-box;
      }

      li:first-child {
        width: 24%;
        border-left: 1px solid #ebeef5;
      }

      li:nth-child(2),
      li:nth-child(3) {
        width: 15%;
      }

      li:nth-child(4) {
        width: 60%;
        border-right: 0;
      }

      li:last-child {
        width: 5%;
        padding: 10px 5px 0 0;
        border-bottom: 0;
        border-right: 0;

        box-sizing: border-box;
      }
    }
  }

  /deep/.el-input {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 93%;
    transform: translate(-50%, -50%);
  }
  /deep/.el-select {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 93%;
    transform: translate(-50%, -50%);
  }
}

/deep/.el-dialog__body {
  padding: 0 20px 15px 20px;
}

/deep/ .el-dialog__footer {
  text-align: center;
  /*background-color: #ebeef5;*/
  padding-bottom: 10px;

  .dialog-footer {
    text-align: center;
  }
}

.teal_bg {
  background-color: #014d67;
  border: 1px solid #014d67;
}

.white_bg {
  color: #014d67;
  border: 1px solid #014d67;
}

.lightGrey_bg {
  color: #666666;
  background-color: #eeeeee;
  border: 1px solid #eeeeee;
}

.m_lr {
  margin: 0 15px;
}

.upload-btn-box {
  display: flex;
  align-items: center;
}
.icon-out {
  cursor: pointer;
  font-size: 28px;
  padding-left: 10px;
  vertical-align: middle;
}
.file-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .file-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.out-box {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .out-a {
    margin-top: 15px;
  }
}

.icon-out {
  cursor: pointer;
  font-size: 28px;
  padding-left: 10px;
  vertical-align: middle;
}
.file-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .file-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.el-icon-question {
  font-size: 20px;
  color: #e6a23c;
}

.fix-dialog-position ::v-deep .el-popper {
  position: absolute !important;
}
</style>
